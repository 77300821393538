/******MainBody Tags Starts******/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin: 0 !important;
  font-size: 16px !important;
  color: #f5f5ff !important;
  padding: 0 !important;
  background-color: #292b2f !important;
}
body.white--theme {
  color: #000 !important;
  padding: 0 !important;
  background-color: #f5f5f5 !important;
}
a {
  cursor: pointer !important;
  text-decoration: none !important;
  color: #f5f5ff !important;
}
.theme-blue-back {
  background-color: #007fff !important;
}
a.white--theme {
  color: #000 !important;
}
.App--Main {
  display: inline-block;
  width: 100%;
}
.Body--Main {
  padding: 44px 0 0 60px;
}
.no-display {
  display: none !important;
}
.mt--5 {
  margin: 5px 0 0 0;
}
.mt--10 {
  margin: 10px 0 0 0;
}
.mt--15 {
  margin: 15px 0 0 0;
}
.mt--20 {
  margin: 20px 0 0 0 !important;
}
.mt--25 {
  margin: 25px 0 0 0;
}
.mt--30 {
  margin: 30px 0 0 0;
}
.mt--35 {
  margin: 35px 0 0 0;
}
.mt--40 {
  margin: 40px 0 0 0;
}
.mt--45 {
  margin: 45px 0 0 0;
}
.mt--50 {
  margin: 50px 0 0 0;
}
.mb--5 {
  margin-bottom: 5px !important;
}
.mb--10 {
  margin-bottom: 10px !important;
}
.mb--15 {
  margin-bottom: 15px !important;
}
.mb--20 {
  margin-bottom: 20px !important;
}
.mb--25 {
  margin-bottom: 25px !important;
}
.rotate-180 {
  transform: rotate(360deg);
}
.rotate-180d {
  transform: rotate(180deg);
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-90r {
  transform: rotate(-90deg) !important;
}
.ib-display {
  display: inline-block !important;
}
.grid-d {
  display: grid !important;
}
.flex-d {
  display: flex !important;
}
.flex-ac {
  align-items: center !important;
}
.flex-jc {
  justify-content: center !important;
}
.flex-jsb {
  justify-content: space-between !important;
}
.flex-jse {
  justify-content: flex-end !important;
}
.flex-gap3 {
  gap: 3px !important;
}
.flex-gap5 {
  gap: 5px !important;
}
.flex-gap7 {
  gap: 7px !important;
}
.flex-gap10 {
  gap: 10px !important;
}
.flex-gap15 {
  gap: 15px !important;
}
.flex-gap20 {
  gap: 20px !important;
}
.flex-gap25 {
  gap: 25px !important;
}
.flex-gap30 {
  gap: 30px !important;
}
.p-all0 {
  padding: 0 !important;
}
.p-all10 {
  padding: 10px !important;
}
.p--b5 {
  padding-bottom: 5px !important;
}
.p--b10 {
  padding-bottom: 10px !important;
}
.p--b15 {
  padding-bottom: 15px !important;
}
.p--b20 {
  padding-bottom: 20px !important;
}
.p--b25 {
  padding-bottom: 25px !important;
}
.p--b30 {
  padding-bottom: 30px !important;
}

.p--t5 {
  padding-top: 5px !important;
}
.p--t10 {
  padding-top: 10px !important;
}
.p--t15 {
  padding-top: 15px !important;
}
.p--t20 {
  padding-top: 20px !important;
}
.p--t25 {
  padding-top: 25px !important;
}
.p--t30 {
  padding-top: 30px !important;
}
.p-lr15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.p-lr10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.body-full-scrollh {
  max-height: calc(100vh - 500px);
  min-height: 400px;
}
.auto-height {
  height: auto !important;
}
.auto-width {
  width: auto !important;
}
.w-100 {
  width: 100% !important;
}
.wh-24 {
  width: 24px;
  height: 24px;
}
.hidden-flow {
  overflow: hidden;
}
.font-14 {
  font-size: 14px !important;
}
.font-12 {
  font-size: 12px !important;
}
.fr-1 {
  grid-template-columns: 1fr !important;
}

.fr-11 {
  grid-template-columns: 1fr 1fr !important;
}
.fr-1111 {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}
.fr1--imp {
  grid-template-columns: 1fr !important;
}
.gap5 {
  gap: 5px;
}
.gap10 {
  gap: 10px;
}
.gap15 {
  gap: 15px;
}
.gap20 {
  gap: 20px;
}
.gap25 {
  gap: 25px;
}

.hide--empty:empty {
  display: none !important;
}
.p-relative {
  position: relative !important;
}
.s-ab-close {
  position: absolute;
  width: 100%;
  bottom: 0;
}
/* .h-40 {
  min-height: 40px;
} */
/******MainBody Tags Starts******/

/**Header Starts**/
.header {
  background: #292b2f;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 0.3px solid #000;
  z-index: 101;
}
.header--end {
  display: flex;
  gap: 15px;
  align-items: center;
}
.white--theme .header {
  background: #fff;
  border-bottom: 0.3px solid #ccc;
}
.header-start__logo {
  margin: 0;
  display: flex;
}
.logo--dark {
  display: flex;
}
.logo--light {
  display: none;
}
.white--theme .header-start__logo .logo--dark {
  display: none;
}
.white--theme .header-start__logo .logo--light {
  display: flex;
}
.user--img {
  display: flex;
  width: 30px;
  height: 30px;
  background: #e50d4b;
  border-radius: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.user--img img {
  display: flex;
  max-width: 30px;
  max-height: 30px;
  border-radius: 100%;
}
.user--img span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
}
.header--end__user {
  position: relative;
}
.header--end__links {
  position: absolute;
  right: 0;
  background: #292b2f;
  border: 1px solid #4a4a4b;
  min-width: 160px;
  top: 40px;
  padding: 5px 0;
}
.white--theme .header--end__links {
  background: #fff;
  border: 1px solid #ddd;
}
.header--end__links a {
  display: flex;
  padding: 0 15px;
  height: 30px;
  align-items: center;
  color: #fff;
}
.white--theme .header--end__links a {
  color: #000 !important;
}
.header--end__links a:hover {
  background: #212529;
}
.white--theme .header--end__links a:hover {
  background: #f5f5f5;
}
/**Header Ends**/

/**Left Menu starts**/
.menu {
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 99;
  background: #292b2f;
  height: 100%;
  width: 60px;
  top: 44px;
  padding: 5px 0 0 0;
}
.white--theme .menu {
  background: #fff;
}
.menu--link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu--link a {
  display: flex;
  width: 35px;
  height: 35px;
  margin: 25px 0 0 0;
  justify-content: center;
  align-items: center;
  background: #e50d4b;
  border-radius: 100%;
}
.menu--link:nth-child(2) a {
  background: #2152a0;
}
.menu--link:nth-child(3) a {
  background: #f8b516;
}
.sub-menu {
  position: relative;
}
.sub-menu-links {
  position: absolute;
  left: 58px;
  top: 25px;
  border-radius: 5px;
  min-width: 200px;
  display: none;
  padding: 10px 0;
  background: #292b2f;
  box-shadow: 0 0 5px 0px rgb(0, 0, 0);
}
.white--theme .sub-menu-links {
  background: #fff;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
}
.sub-menu:hover .sub-menu-links {
  display: grid;
}
.sub-menu-links a {
  background: none;
  width: auto;
  height: auto;
  margin: 0;
  padding: 5px 12px;
  justify-content: flex-start;
  display: flex;
  font-size: 15px;
  width: 100%;
  border-radius: 0;
}
.white--theme .sub-menu-links a {
  color: #000 !important;
}
.sub-menu-links a:hover {
  background: #303338;
}
.white--theme .sub-menu-links a:hover {
  background: #f5f5f5;
}
/**Left Menu Ends**/

/**Chat Starts**/

/**Left Menu Starts**/
.msg-main {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) 6fr;
}
.msg--menu {
  background: #303338;
  border-right: 0.3px solid #000;
  height: calc(100vh - 44px);
}
.sm--msg__menu {
  width: 30px;
}
.white--theme .msg--menu {
  background: #eee;
  border-right: 0.3px solid #ccc;
}
.msg--menu__head__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 33px;
  border-bottom: 0.3px solid #000;
  font-weight: 500;
}
.flex-js {
  justify-content: flex-start !important;
}
.white--theme .msg--menu__head__content {
  border-bottom: 0.3px solid #ccc;
}
.msg--menu__ctg {
  margin: 10px 0 0 0;
  font-size: 14px;
  color: #9a9a9b;
}
.white--theme .msg--menu__ctg {
  color: #707070;
}
.msg--menu__ctg__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 35px;
}
.msg--menu__ctg__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 23px;
  height: 28px;
  cursor: pointer;
  border-right: none;
  font-size: 12px;
}
.msg--menu__ctg__inner.msg--menu.LeftSelectedClass {
  background: #292b2f !important;
  color: #fff;
}
.white--theme .msg--menu__ctg__inner.msg--menu.LeftSelectedClass {
  background: #ddd !important;
  color: #000;
}
.msg--menu__ctg__top.CategoryLeftSelectedClass {
  background: #292b2f !important;
  color: #fff;
}
.white--theme .msg--menu__ctg__top.CategoryLeftSelectedClass {
  background: #ddd !important;
  color: #000;
}
.msg--menu__ctg__inner .ctg--grey {
  display: flex;
}
.msg--menu__ctg__inner .ctg--white {
  display: none;
}
.msg--menu__ctg__inner:hover {
  background-color: #292b2f !important;
  color: #fff;
}
.white--theme .msg--menu__ctg__inner:hover {
  background-color: #ddd !important;
  color: #000;
}
.msg--menu__ctg__inner:hover .ctg--white {
  display: flex;
}
.msg--menu__ctg__inner:hover .ctg--grey {
  display: none;
}
.msg--menu__ctg__inner.msg--menu__selected .ctg--white {
  display: flex;
}
.msg--menu__ctg__inner.msg--menu__selected .ctg--grey {
  display: none;
}
.ctg--top__lt {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.ctg--top__rt {
  position: relative;
  cursor: pointer;
}
.ctg--top__rt.ctg--top__rt__set {
  display: flex;
  width: 14px;
  height: 14px;
}
.ctg--top__rt img {
  width: 12px;
  height: 12px;
}
.top__lt__fst {
  display: flex;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  margin: 0 0px 0 0;
}
.msg--menu__ctg__inner .top__lt__fst img {
  width: 13px;
  height: 14px;
}
.ctg--top__lt span {
  font-weight: 500;
}
.msg--menu__ctg__inner .ctg--top__lt span {
  font-weight: 400;
}
.msg--menu__ctg__inner .top__lt__fst {
  margin: 0 8px 0 0;
}
.msg--menu__chat {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
}
.msg--menu__chat:hover {
  background-color: #292b2f;
  color: #fff;
}
.white--theme .msg--menu__chat:hover {
  background-color: #ddd;
  color: #000;
}
.msg--menu__selected {
  background-color: #2152a0 !important;
  color: #fff;
}
.msg--menu__chat__user {
  display: flex;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  background: #e50d4b;
  margin: 0 15px 0 0;
}
.msg--menu__chat__user img {
  max-width: 22px;
  max-height: 22px;
  width: auto;
  height: auto;
  border-radius: 100%;
}
.msg--menu__chat__user span {
  font-size: 10px;
  font-weight: 600;
  color: #fff;
}
.white--theme .msg--menu__chat__user span {
  color: #000;
}
/**Left Menu Ends**/

/**Chat body Starts**/
.msg--body {
  display: flex;
}
.msg--body__lt {
  width: 100%;
}
.msg--body__lt.msg--body__lt__sm {
  max-width: calc(100% - 300px);
}
.msg--body__lt.msg--body__rt {
  width: 300px;
  border-left: 1px solid #000;
}
.white--theme .msg--body__lt.msg--body__rt {
  border-left: 1px solid #ccc;
}
.msg--body__lt__head {
  display: flex;
  height: 33px;
  border-bottom: 0.3px solid #000;
  align-items: center;
  padding: 0 15px;
}
.white--theme .msg--body__lt__head {
  border-bottom: 0.3px solid #ccc;
}
.msg--body__messages {
  height: calc(100vh - 97px);
  position: relative;
}
.msg--body__messages__chats {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  max-height: calc(100vh - 100px);
}
.msg--body__messages__chats__scroll {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 120px;
  max-height: calc(100vh - 220px);
  overflow: auto;
}
.msg--body__user {
  display: flex;
  align-items: center;
}
.msg--body__user__wrap {
  display: flex;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background: #e50d4b;
}
.msg--body__user__img {
  max-width: 22px;
  max-height: 22px;
  width: auto;
  height: auto;
  border-radius: 100%;
}
.msg--body__initials {
  font-size: 10px;
  font-weight: 600;
}
.msg--body__initials {
  font-size: 10px;
  font-weight: 600;
}
.msg--body__name {
  font-weight: 600;
  margin: 0 0 0 10px;
}
.msg--body__type {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 15px;
}
.msg--body__type textarea {
  background: #3b3e46;
  border: 1px solid #3b3e46;
  resize: none;
  border-radius: 10px 10px 0 0;
  width: 100%;
  min-height: 70px;
  height: 70px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0;
  font-family: "Poppins", sans-serif;
  padding: 24px 50px 10px 50px;
  overflow: hidden;
}
.white--theme .msg--body__type textarea {
  background: #ddd;
  border: 1px solid #ddd;
  color: #000;
}
.msg--body__attachment {
  position: absolute;
  left: 30px;
  display: flex;
  top: 25px;
  z-index: 100;
}
.msg--body__date_sepr {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9b;
  position: relative;
  padding: 0 15px;
  margin: 20px 0;
}
.white--theme .msg--body__date_sepr {
  color: #707070;
}
.msg--body__date_sepr {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9b;
  position: relative;
  padding: 0 15px;
  margin: 20px 0;
}
.msg--body__date_sepr span {
  position: relative;
  z-index: 100;
  background: #2e3239;
  padding: 0 15px;
}
.white--theme .msg--body__date_sepr span {
  background: #f5f5f5;
}
.msg--body__date_sepr hr {
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
  margin: 0;
  border-width: 0.1px;
  z-index: 99;
}
.msg--body__post__chat {
  padding: 10px 15px;
  margin: 0 0 10px 0;
  position: relative;
}
.msg--body__post__chat:hover {
  background-color: #292b2f;
}
.white--theme .msg--body__post__chat:hover {
  background-color: #eee;
}
.post__chat__name__time {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
}
.post__chat__user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #e50d4b;
}
.post__chat__user img {
  max-width: 30px;
  max-height: 30px;
  width: auto;
  height: auto;
  border-radius: 100%;
}
.post__chat__message {
  margin: 0 0 0 10px;
}
.post__chat__time {
  font-weight: 400;
  color: #9a9a9b;
  margin: 0 0 0 10px;
}
.post__chat__main__message {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
}
.post__chat__message__time {
  display: flex;
  width: 40px;
}
.edited--message {
  width: 12px;
  height: 12px;
  margin: -1px 0 0 10px;
  opacity: 0.5;
}
.post__chat__message__text {
  width: calc(100% - 40px);
  white-space: pre-line;
}
.message-attachment {
  display: flex;
  padding: 10px 0 0 40px;
  gap: 8px;
}
.message-attachment a {
  background: #3b3e46;
  border-radius: 8px;
  height: 230px;
  align-items: center;
  display: flex;
  max-width: 330px;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #4a4a4b;
  padding: 0 8px;
  cursor: zoom-in !important;
  margin: 0 0 8px 0;
  min-width: 120px;
}
.white--theme .message-attachment a {
  background: #eee;
  border: 1px solid #ddd;
  color: #000 !important;
}
.message-attachment a img {
  width: 100%;
  height: auto;
}
.msg--body__actions__link {
  position: absolute;
  background: #222326;
  border: 0.2px solid #ccc;
  z-index: 101;
  left: 30px;
  bottom: calc(100% - 20px);
  padding: 5px 0;
}
.msg--body__actions__link a {
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 0 12px;
  height: 30px;
}
.msg--body__actions__link a:hover {
  background: #292b2f;
}
.msg--body__actions__link a img {
  margin: 0 10px 0 0;
}
.msg--body__uploader {
  position: absolute;
  background: #222326;
  z-index: 102;
  border: 0.2px solid #ccc;
  left: 30px;
  bottom: calc(100% - 0px);
  width: 400px;
}
.msg--body__smiley__anchor {
  position: absolute;
  right: 30px;
  top: 22px;
  transition: all 0.3s ease;
}
.msg--body__smiley__anchor:hover img {
  transform: scale(1.2);
  transition: all 0.3s ease;
  /* transform: rotate(360deg); */
}
.msg--body__picker {
  position: absolute;
  right: 30px;
  bottom: 55px;
  z-index: 100;
}
.msg--body__send {
  display: flex;
  margin: 0 15px;
  justify-content: flex-end;
  background: #292b2f;
  border-radius: 0 0 10px 10px;
  padding: 8px 15px;
}
.white--theme .msg--body__send {
  background: #ccc;
}
.msg--body__send__anchor {
  display: flex;
  transition: all 0.3s ease;
}
.msg--body__send__btnw {
  display: flex;
  transition: all 0.3s ease;
}
.msg--body__send__btnb {
  display: none;
  transition: all 0.3s ease;
}
.msg--body__send__anchor:hover .msg--body__send__btnw {
  display: none;
  transition: all 0.3s ease;
}
.msg--body__send__anchor:hover .msg--body__send__btnb {
  display: flex;
  position: relative;
  transform: scale(1.2);
  transition: all 0.3s ease;
}
.msg--body__picker aside.emoji-picker-react {
  background: #292b2f;
  border: 1px solid #303338;
  box-shadow: none;
}
.white--theme .msg--body__picker aside.emoji-picker-react {
  background: #fff;
  border: 1px solid #ccc;
}
.msg--body__picker .emoji-picker-react input.emoji-search {
  border: 1px solid #3b3e46;
  background: #3b3e46;
  color: #fff;
}
.white--theme .msg--body__picker .emoji-picker-react input.emoji-search {
  border: 1px solid #eee;
  background: #f5f5f5;
  color: #000;
}
.msg--body__picker .emoji-picker-react .emoji-group::before {
  color: #fff;
  background: #292b2f;
}
.white--theme .msg--body__picker .emoji-picker-react .emoji-group::before {
  color: #000;
  background: #fff;
}
.msg--body__picker .emoji-picker-react .emoji-categories button {
  filter: invert(1);
  opacity: 1 !important;
}
.white--theme .msg--body__picker .emoji-picker-react .emoji-categories button {
  filter: invert(0);
}
.msg--body__thread {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.msg--body__thread__icons {
  display: flex;
  align-items: center;
}
.msg--body__thread__icons a {
  display: flex;
  margin: 0 0 0 10px;
}
.msg--body__thread__icons a:first-child {
  margin: 0;
}

/**Thread Starts**/
.post--chat__thread__main {
  font-size: 12px;
  padding: 0 0 0 40px;
  display: inline-block;
  margin: 15px 0 10px 0;
}
.post--chat__thread {
  display: flex;
  background: #3b3e46;
  padding: 8px 15px;
  border-radius: 5px;
  align-items: center;
}
.white--theme .post--chat__thread {
  background: #ddd;
}
.white--theme .post--chat__thread__message {
  color: #000 !important;
}
.post--chat__thread__count {
  display: flex;
  align-items: center;
  margin: 0 0 0 12px;
  color: #007fff;
}
.post--chat__thread__count img {
  width: 4px;
  height: 8px;
  margin: 0 0 0 5px;
}
.post--chat__thread__time {
  font-size: 10px;
  color: #9a9a9b;
  margin: 0 0 0 15px;
}
.white--theme .post--chat__thread__time {
  color: #707070;
}
/**Thread Ends**/

/**Emoji Starts**/
.msg--body__emoji {
  display: none;
  position: absolute;
  right: 15px;
  top: -16px;
  background: #3b3e46;
  border-radius: 5px;
  align-items: center;
  padding: 5px 5px;
}
.white--theme .msg--body__emoji {
  background: #bbb;
}
.msg--body__emoji:hover {
  display: flex;
}
.msg--body__post__chat:hover .msg--body__emoji {
  display: flex;
}
.msg--body__emoji a {
  display: flex;
  margin: 0 4px;
}
.edit--del {
  position: relative;
}
.edit--del--drop {
  position: absolute;
  right: 12px;
  background: #222326;
  border: 0.2px solid #ccc;
  top: 25px;
  width: 150px;
  padding: 10px 0;
}
.white--theme .edit--del--drop {
  background: #bbb;
  border: 0.2px solid #ccc;
}
.edit--del--drop a {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  height: 26px;
  padding: 0 10px;
}
.edit--del--drop a:last-child span {
  color: #e50d4b;
}
.edit--del--drop a:hover {
  background: #292b2f;
}
.white--theme .edit--del--drop a:hover {
  background: #eee;
}
.edit--del--drop img {
  width: 13px;
  height: 13px;
  margin: 0 10px 0 0;
}
.del--confirm {
  display: flex;
  margin: 10px 0 10px 0;
  font-size: 15px;
}
/**Emoji Ends**/

/**Reactions Starts**/
.msg--body__react__tags {
  display: flex;
  padding: 0 0 0 40px;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  margin: 5px 0 0 0;
}
.msg--body__reacts {
  display: flex;
}
.msg--body__reacts a {
  display: flex;
  align-items: center;
  background-color: rgba(0, 127, 255, 0.15);
  border: 1px solid #007fff;
  border-radius: 5px;
  margin: 0 5px 0 0;
  padding: 1px 5px;
}
.msg--body__reacts a img {
  margin: 0 5px 0 0;
}
/**Reactions Ends**/

/**Chat body Ends**/

/**Chat Ends**/

/** Tasks Starts  **/
.sm-check--btn {
  margin: 0 !important;
  padding: 0 0 0 30px !important;
}
.sm-check--btn input {
  width: 22px !important;
  height: 22px !important;
  top: auto !important;
}
.sm-check--btn .check--mark {
  width: 20px;
  height: 20px;
  top: auto !important;
}
.sm-check--btn.check--btn .check--mark::after {
  top: auto !important;
  width: 6px !important;
  height: 10px !important;
}
.sm-check--btn .input--check__text {
  font-size: 14px;
}
.task-board {
  background: #292b2f;
  /* box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.4); */
  width: 300px;
  min-width: 300px;
  border-radius: 5px;
}
.white--theme .task-board {
  background: #eee;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
.task-list {
  max-height: calc(100vh - 150px);
  padding: 10px;
}
.task-head {
  display: flex;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  letter-spacing: 1px;
}
/* .white--theme .task-head {
  background: #fff;
} */
.s-tasks {
  background: #303338;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer !important;
}
.white--theme .s-tasks {
  background: #fff;
}
.task-detail-fix {
  position: fixed;
  right: 0;
  top: 44px;
  background: #000;
  width: 400px;
  z-index: 100;
  height: calc(100vh - 44px);
}
.white--theme .task-detail-fix {
  background: #e3e3e3;
}
.expand-view {
  width: calc(100% - 60px);
}

.task-chat-sm .msg--body__messages {
  max-height: calc(100vh - 400px);
}
.task-chat-sm .msg--body__messages__chats {
  max-height: calc(100vh - 403px);
}
.task-chat-sm .msg--body__messages__chats__scroll {
  max-height: calc(100vh - 523px);
}
.Support-chat .msg--body__messages {
  max-height: calc(100vh - 370px);
}
.Support-chat .msg--body__messages__chats {
  max-height: calc(100vh - 373px);
}
.Support-chat .msg--body__messages__chats__scroll {
  max-height: calc(100vh - 493px);
}
.nev-nncs {
  height: calc(100vh - 358px);
  overflow: auto;
  min-height: 200px;
}
.nev-nncs2 {
  height: calc(100vh - 318px);
  overflow: auto;
  min-height: 200px;
}
.nev-ycs {
  height: calc(100vh - 910px);
  overflow: auto;
  min-height: 200px;
}
.task-chat-sm {
  /* border-top: 1px solid #4c4c4c; */
}
.m-accord {
  background: #3b3e46;
  padding: 5px 10px;
  margin: 10px 0 0 0;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
}
.btn-internal {
  font-size: 12px;
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0;
  color: #e50d4b !important;
  text-decoration: underline !important;
  margin: 0 15px 0px 0;
}
.white--theme .m-accord {
  background: #ccc;
  color: #000 !important;
}
.asgn-task {
  font-size: 11px;
  color: #8c8c8c;
  font-style: italic;
  margin: 5px 0 0 0;
}
.event-title {
  font-size: 20px;
  margin: 0;
}
.toggle-btns {
  border-radius: 0px;
  font-size: 12px;
}
.toggle-btns a {
  padding: 0 6px;
  min-width: 120px;
  height: 32px;
  color: #fff !important;
  text-transform: uppercase;
}
.white--theme .toggle-btns a {
  color: #000 !important;
}
.toggle-btns a:last-child {
  border: none;
}
.toggle-btns a.selected {
  color: #fff !important;
  background: #007fff;
}
.white--theme .toggle-btns a.selected {
  background: #007fff;
}
.task-name {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
}
.task-descp {
  font-size: 12px;
}
.sm-greyfont {
  font-size: 11px;
  color: #9a9a9b;
}
/** Tasks Ends  **/

/*Theme Btn Starts*/
.btn--theme {
  position: fixed;
  top: 10px;
  right: 110px;
  z-index: 1000;
}
/*Theme Btn Ends*/

/**App Dropdowns Starts**/
.app--drops {
  position: absolute;
  left: 0;
  background: #222326;
  border: 0.2px solid #ccc;
  width: 190px;
  z-index: 100;
  padding: 10px 0;
}
.white--theme .app--drops {
  background: #bbb;
}
.app--drops__item {
  display: flex;
  font-size: 12px;
  align-items: center;
  height: 30px;
  padding: 0 12px;
}
.app--drops__item img {
  margin: 0 10px 0 0;
}
.app--drops__item:hover {
  background-color: #292b2f;
}
.white--theme .app--drops__item:hover {
  background-color: #eee;
}
/**App Dropdowns Ends**/

/**Settings Starts**/
.settings--main {
  color: #9a9a9b;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}
.settings--tab.nav.nav-tabs {
  border-bottom: 1px solid rgba(154, 154, 155, 0.2) !important;
}
.settings--main .settings--tab {
  padding: 20px 50px 0px 50px;
}
.settings--main .tab-pane {
  padding: 0 0 0 50px;
  max-width: 600px;
}
.login--strong__passcode .p-error {
  color: #bd362f;
  background-image: url(https://travelapp.manyge.com//images/icon-cross.svg);
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 12px;
  padding: 0 0 0 20px;
}
.login--strong__passcode .p-ok {
  color: #51a351;
  background-image: url(https://travelapp.manyge.com/images/Icon-check.svg);
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 12px;
  padding: 0 0 0 20px;
}
/**Settings Ends**/

/**Create modal Starts**/
.modal--create {
  color: #9a9a9b;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  min-height: 550px;
}
.modal--create__about {
  font-weight: 400;
  display: inline-block;
  width: 100%;
  margin: 15px 0 0 0;
}
.modal--create__inputs {
  display: inline-block;
  width: 100%;
}
option {
  background: #292b2f !important;
}
.white--theme option {
  background: none !important;
}
.input--text {
  display: flex;
  margin: 0 0 2px 0;
}
.input--type__text {
  /* display: flex; */
  width: 100%;
  height: 44px;
  background: no-repeat;
  border-radius: 5px;
  border: 1px solid #9a9a9b;
  color: #f5f5ff;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
}

.input--type__text_no_border {
  /* display: flex; */
  width: 100%;
  background: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input--type__textsm {
  height: 32px;
  font-size: 14px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background-image: url(/public/images/calendar.svg);
  cursor: pointer;
  background-size: 18px;
}
.white--theme .input--type__text,
.white--theme .input--type__text_no_border {
  color: #000;
}
.modal--create__inputs textarea {
  /* resize: none; */
  height: 70px;
  padding: 15px;
}
.input--type__textarea {
  resize: vertical;
  height: 100px;
  font-size: 14px;
}
.modal--create__inputs__radio {
  display: flex;
  align-items: center;
}
.modal--create__inputs__radio {
  display: flex;
  align-items: center;
}
.input--radio {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 0 0 48px;
  margin: 0 50px 15px 0;
}
.input--radio__text,
.input--check__text {
  font-size: 18px;
  font-weight: 400;
}
.create--hr {
  margin: 20px -25px 0 -25px;
  opacity: 1;
  border-top: 0.2px solid #9a9a9b;
}
.modal--create__add {
  float: left;
  width: 100%;
  border: 1px solid #9a9a9b;
  border-radius: 5px;
  padding: 6px 10px;
  margin: 0 0 -5px 0;
}
.modal--create__add__member {
  float: left;
  margin: 0 5px;
}
.modal--create__add__member__flex {
  display: flex;
  align-items: center;
  background: #303338;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  font-size: 10px;
  color: #f5f5ff;
  font-weight: 600;
}
.modal--create__add__member__image {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
  background: #e50d4b;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.modal--create__add__member__image img {
  width: auto;
  height: auto;
  max-width: 20px;
  max-height: 20px;
}
.modal--create__add__member__name {
  margin: 0 8px;
  font-size: 11px;
}
.remove--user {
  width: 10px;
  height: 10px;
}
.modal--button {
  display: flex;
  margin: 10px 0 0px 0;
  justify-content: flex-end;
}
.del--modal__btn {
  gap: 15px;
  margin: 25px 0 0 0 !important;
}
.modal--button a {
  display: flex;
  background: #007fff;
  height: 38px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px;
}
.del--modal__btn a:first-child {
  background: none;
  border: 2px solid #9a9a9b;
  min-width: 102px;
}
.del--modal__btn a:last-child {
  background: #e50d4b;
  min-width: 102px;
}
.edit--input {
  position: relative;
}
.edit--input a {
  position: absolute;
  right: 15px;
  top: calc(50% - 7.5px);
  display: none;
}
.edit--input:hover a {
  display: flex;
}
.edit--input input,
.edit--input textarea {
  border: none;
  padding-left: 0;
}
.edit--input.edit--input--start input,
.edit--input.edit--input--start textarea {
  border: 1px solid #9a9a9b;
  padding-left: 15px;
}
/* .edit--input:hover input,
.edit--input:hover textarea {
  border: 1px solid #9a9a9b;
  padding-left: 15px;
} */
.channel--archive {
  display: flex;
  margin: 10px 0 20px 0;
}
.channel--archive a {
  display: flex;
  color: #e50d4b !important;
  font-size: 18px;
  align-items: center;
  font-weight: 400;
}
.channel--archive a img {
  margin: 0 20px 0 0;
}
.modal--create__inputs.search--members input {
  background-image: url(/public/images/search.png);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 15px center;
  padding: 0 0 0 45px;
}
.add-remaining {
  display: flex;
  align-items: center;
  margin: 20px 0 -10px 0;
}
.add-remaining span {
  width: auto;
  margin: 0;
}
.add-remaining a {
  font-size: 12px;
  line-height: 12px;
  padding: 10px 12px;
  background: #007fff;
  border-radius: 5px;
  margin: 0 0 0 20px;
  font-weight: 400;
}
.online--users {
  display: inline-block;
  width: 100%;
  margin: 10px 0 0 0;
}
.online--users__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 0 0;
}
.online--users__data {
  display: flex;
  align-items: center;
}
.online--users__images {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #e50d4b;
  border-radius: 100%;
  justify-content: center;
  position: relative;
}
.online--users__images img {
  max-width: 30px;
  max-height: 30px;
  width: auto;
  height: auto;
  border-radius: 100%;
}
.online--users__status {
  position: absolute;
  right: 1px;
  bottom: 0px;
  width: 7px !important;
  height: 7px !important;
}
.online--users__status {
  position: absolute;
  right: 1px;
  bottom: 0px;
}
.online--users__name {
  margin: 0 0 0 15px;
}
.online--users__remove {
  display: none;
}
.online--users__details:hover .online--users__remove {
  display: flex;
}
/**Create modal Ends**/

/**App Modals Starts**/
.modal-dialog {
  min-width: 600px;
  margin-top: 100px !important;
}
.modal-content {
  background: #292b2f !important;
  border-radius: 10px !important;
  color: #f5f5ff !important;
  font-family: "Poppins", sans-serif !important;
}
.modal-header {
  border: none !important;
  height: 60px !important;
  padding: 0 25px !important;
  justify-content: space-between;
  align-items: center !important;
  display: flex !important;
}
.modal-header img {
  margin: -5px 10px 0 0;
}
.modal-title.h4 {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.modal-header .btn-close {
  padding: 0 !important;
  font-size: 20px !important;
  margin: 0 !important;
  color: #f5f5ff !important;
  filter: invert(1) !important;
}
.modal-body {
  padding: 0 25px 25px 25px !important;
}
/**App Modals Ends**/

/**Radio Starts**/
/* The radio--btn */
.radio--btn {
  position: relative;
}

/* Hide the browser's default radio button */
.radio--btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  left: 0;
  top: 0;
  z-index: 100;
}

/* Create a custom radio button */
.radio--mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: none;
  border-radius: 50%;
  border: 2px solid #9a9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* On mouse-over, add a grey background color */
.radio--btn:hover input ~ .radio--mark {
  background-color: none;
}

/* When the radio button is checked, add a blue background */
.radio--btn input:checked ~ .radio--mark {
  background-color: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio--mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio--btn input:checked ~ .radio--mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio--btn .radio--mark:after {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #9a9a9b;
}
/**Radio Ends**/

/**Checkbox Starts**/
.check--btn {
  position: relative;
}

/* Hide the browser's default radio button */
.check--btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  left: 0;
  top: 0;
  z-index: 100;
}

/* Create a custom radio button */
.check--mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: none;
  border-radius: 5px;
  border: 2px solid #9a9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* On mouse-over, add a grey background color */
.check--btn:hover input ~ .check--mark {
  background-color: none;
}

/* When the radio button is checked, add a blue background */
.check--btn input:checked ~ .check--mark {
  background-color: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check--mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.check--btn input:checked ~ .check--mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.check--btn .check--mark:after {
  width: 8px;
  height: 14px;
  border: solid #9a9a9b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 4px;
}
/**Checkbox Ends**/

/**Scroll Starts**/
.custom--scroll {
  scrollbar-color: #292b2f Transparent;
  scrollbar-width: thin;
  overflow: auto;
}
.custom--scroll::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
  border-radius: 0;
}
.custom--scroll::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}
.custom--scroll::-webkit-scrollbar-thumb {
  background: #292b2f;
  border-radius: 0;
}
.custom--scroll::-webkit-scrollbar-thumb:hover {
  background: #292b2f;
}
/*Light Scroll*/
.white--theme .custom--scroll {
  scrollbar-color: #ddd Transparent;
  scrollbar-width: thin;
}
.white--theme .custom--scroll::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 0;
}
.white--theme .custom--scroll::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}
/**Scroll Ends**/

/**Tabs Starts**/
.nav-link {
  background: none !important;
  border: none !important;
  color: #9a9a9b !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  border-bottom: 3px solid transparent !important;
  padding: 8px 31px !important;
}
.nav-link.active {
  color: #fff !important;
  border-bottom: 3px solid #007fff !important;
}
.nav-link.active:hover {
  color: #fff !important;
  border-bottom: 3px solid #007fff !important;
}
.nav-link:hover {
  border: none !important;
  color: #fff !important;
  border-bottom: 3px solid transparent !important;
}
.modal--create__about.nav.nav-tabs {
  border-bottom: 1px solid rgba(154, 154, 155, 0.2) !important;
}
.modal--create .modal--create__about.nav.nav-tabs {
  margin: 15px 0 0 -25px;
  width: calc(100% + 50px);
  padding: 0 25px;
}
/**Tabs Ends**/
/**Loader Starts**/
.loader-overlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
}
/**Loader Ends**/
/**Uploader Starts**/
.msg--body__dragdrop {
  padding: 0 15px;
  margin: 15px 0;
}
.dropArea {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
}
.dropAreatext {
  border: 2px dashed #ccc;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  color: #ccc;
  position: relative;
  cursor: pointer;
}
.dropAreatext:hover {
  background: #f5f5f5;
}
.remove--image {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
  margin: 20px 0 5px 0;
}
.remove--image img {
  width: auto;
  height: auto;
  max-height: 100px;
  max-width: 100px;
}
.remove--image a {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
}
/**Uploader Ends**/
.min-h-100 {
  min-height: 100px !important;
}

/**Responsive Starts**/
@media (max-width: 980px) {
  .msg--menu__ctg__inner .ctg--white {
    display: flex !important;
  }
}
/**Responsive Ends**/

/*table Starts*/
.overflow-auto {
  overflow: auto;
  max-height: calc(100vh - 80px);
  padding-bottom: 12px;
}
.reuse-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #444;
  min-width: 100%;
}
.white--theme .reuse-table {
  border: 1px solid #ddd;
}
.reuse-table thead tr th {
  background: #101010;
  color: #fff !important;
  font-weight: bold;
  height: 32px;
  padding: 0 5px;
  border-right: 1px solid #444;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-size: 10px;
  text-align: center;
  border-bottom: 1px solid #444;
  line-height: 12px;
}
.white--theme .reuse-table thead tr th {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: #ccc;
}
.reuse-table tbody tr td {
  font-weight: normal;
  height: 32px;
  padding: 0 5px;
  border-right: 1px solid #444;
  font-size: 11px;
  border-bottom: 1px solid #444;
}
.white--theme .reuse-table tbody tr td {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.reuse-table tbody tr:nth-child(2n + 1),
.reuse-table tbody tr:nth-child(2n + 1) td {
  background: #272b32;
}
.white--theme .reuse-table tbody tr:nth-child(2n + 1),
.white--theme .reuse-table tbody tr:nth-child(2n + 1) td {
  background: #fafafa;
}
tr:first-child .sticky-header {
  position: sticky;
  top: -1px;
  z-index: 100;
}
tr:nth-child(2) .sticky-header {
  position: sticky;
  top: 30px;
  z-index: 100;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.input-check {
  width: auto !important;
  height: auto !important;
  accent-color: #2152a0 !important;
}
.reuse-table tbody tr td.col-xxs,
.reuse-table thead tr th.col-xxs {
  width: 100px !important;
}
.reuse-table td input,
.reuse-table td select {
  width: 100%;
  background: transparent;
  border: 2px solid transparent;
  height: 32px;
  outline: none;
  color: #fff;
  padding: 0 5px;
  font-size: 11px;
}
.white--theme .reuse-table td input,
.white--theme .reuse-table td select {
  color: #000;
}
.reuse-table td input:hover,
.reuse-table td select:hover {
  border: 2px solid #9a9a9b;
}
.reuse-table td.p-all0 {
  padding: 0 !important;
}
.reuse-min-wid th,
.reuse-min-wid td {
  min-width: 200px !important;
}
.p-unset {
  position: unset !important;
}
/*table Ends*/

/*Reporting Starts*/
.report-accord {
  margin: 20px 0 0 0;
}
.acco-head {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #9a9a9b;
  padding: 0 0 2px 0;
  margin: 0 0 10px 0;
  letter-spacing: 1px;
  color: #9a9a9b;
}
.btn-info-rep a {
  border: 2px solid #9a9a9b;
  border-radius: 5px;
  color: #9a9a9b !important;
  font-size: 12px;
  padding: 0 10px;
  height: 36px;
}
.c-line {
  display: flex;
  width: 1px;
  height: 15px;
  background: #9a9a9b;
}
.txt-label-sm {
  color: #9a9a9b;
  font-size: 12px;
  text-transform: uppercase;
}
.grid-w {
  width: 100%;
  max-width: 10%;
}
/*Reporting Ends*/

.resizer {
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  z-index: 1;
}

.isResizing {
  background: rgba(1, 0, 0, 2);
}

/* table {
  table-layout: fixed;
} */

/*Filter Drop Starts*/
.table-filter-drop {
  background: #292b2f;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0px rgb(0, 0, 0);
  min-width: 200px;
}
.filter-scroll .no-wrap {
  font-size: 12px;
}
.white--theme .table-filter-drop {
  background: #fff;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
}
/*Support Ticket Starts*/
.status-box {
  height: 26px;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
}
.cursor-p {
  cursor: pointer;
}
.priority-box {
  width: 8px;
  height: 8px;
  border-radius: 30px;
}
tr.cursor-p:hover td {
  background: #1a1a1a !important;
}
.reuse-border {
  border-right: 1px solid #9a9a9b;
  min-height: 170px;
}
.p-box-change {
  width: 100%;
  max-width: 250px;
  background: #212529;
  height: 32px;
  padding: 0 8px;
  border-radius: 3px;
  font-size: 12px;
  color: #9a9a9b;
  font-weight: 600;
  cursor: pointer;
}
.white--theme .p-box-change {
  background: #ddd;
}
.priority-drop {
  position: absolute;
  background: #212529;
  width: 100%;
  max-width: 250px;
  top: 34px;
  left: 0;
  border-radius: 3px;
}
.white--theme .priority-drop {
  background: #ddd;
}
.priority-drop a {
  padding: 0 8px !important;
  color: #9a9a9b !important;
  text-transform: uppercase;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
}
.grey-box-support {
  background: #f5f5f5;
  padding: 0px 8px;
  height: 52px;
  border-radius: 3px;
  cursor: pointer;
}
.grey-box-support img {
  width: 20px;
  height: auto;
  max-width: 20px;
  max-height: 20px;
}
.grey-box-support img.sup-chev {
  width: 8px;
  height: 4px;
}
.grey-box-drop {
  top: 75px;
  left: 0;
  width: 100%;
  height: auto;
  box-shadow: 1px 2px 4px #e8e8e8;
  padding: 10px 8px;
}
.support-drop {
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  color: #9a9a9b !important;
}
.support-area textarea {
  min-height: 170px;
}
.suport-attach .drad-drop {
  width: 100%;
}
.suport-attach .uploaded-list {
  display: flex;
}
.p-rt {
  right: 30px;
  top: 34px;
}
.ticket-imgs img {
  height: 100px;
  width: auto;
}
.bold-font {
  font-weight: 600;
}
.reuse-back-l-g {
  background: #292b2f;
  border-radius: 3px;
  padding: 10px;
}
.white--theme .reuse-back-l-g {
  background: #ececec;
}

.white--theme .reuse-goback a {
  color: #000 !important;
}

/**Header Starts**/
.header {
  background: #303338;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid #000;
  z-index: 101;
}
.header--end {
  display: flex;
  gap: 15px;
  align-items: center;
}
.white--theme .header {
  background: #fff;
  border-bottom: 0.3px solid #ccc;
}
.header-start__logo {
  margin: 0;
  display: flex;
}
.logo--dark {
  display: flex;
}
.logo--light {
  display: none;
}
.white--theme .header-start__logo .logo--dark {
  display: none;
}
.white--theme .header-start__logo .logo--light {
  display: flex;
}
.user--img {
  display: flex;
  width: 30px;
  height: 30px;
  background: #e50d4b;
  border-radius: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.user--img img {
  display: flex;
  max-width: 30px;
  max-height: 30px;
  border-radius: 100%;
}
.user--img span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
}
.header--end__user {
  position: relative;
}
.header--end__links {
  position: absolute;
  right: 0;
  background: #292b2f;
  border: 1px solid #4a4a4b;
  min-width: 160px;
  top: 40px;
  padding: 5px 0;
}
.white--theme .header--end__links {
  background: #fff;
  border: 1px solid #ddd;
}
.header--end__links a {
  display: flex;
  padding: 0 15px;
  height: 30px;
  align-items: center;
  color: #fff;
}
.white--theme .header--end__links a {
  color: #000 !important;
}
.header--end__links a:hover {
  background: #212529;
}
.white--theme .header--end__links a:hover {
  background: #f5f5f5;
}
/**Header Ends**/

/**Left Menu starts**/
.menu {
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 99;
  background: #303338;
  height: 100%;
  width: 60px;
  top: 44px;
  padding: 5px 0 0 0;
  border-right: 1px solid #000;
}
.white--theme .menu {
  background: #fff;
}
.menu--link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu--link a {
  display: flex;
  width: 35px;
  height: 35px;
  margin: 25px 0 0 0;
  justify-content: center;
  align-items: center;
  background: #e50d4b;
  border-radius: 100%;
}
.menu--link:nth-child(2) a {
  background: #2152a0;
}
.menu--link:nth-child(3) a {
  background: #f8b516;
}
.sub-menu {
  position: relative;
}
.sub-menu-links {
  position: absolute;
  left: 58px;
  top: 25px;
  border-radius: 5px;
  min-width: 200px;
  display: none;
  padding: 10px 0;
  background: #292b2f;
  box-shadow: 0 0 5px 0px rgb(0, 0, 0);
}
.white--theme .sub-menu-links {
  background: #fff;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
}
.sub-menu:hover .sub-menu-links {
  display: grid;
}
.sub-menu-links a {
  background: none;
  width: auto;
  height: auto;
  margin: 0;
  padding: 5px 12px;
  justify-content: flex-start;
  display: flex;
  font-size: 15px;
  width: 100%;
  border-radius: 0;
}
.white--theme .sub-menu-links a {
  color: #000 !important;
}
.sub-menu-links a:hover {
  background: #303338;
}
.white--theme .sub-menu-links a:hover {
  background: #f5f5f5;
}
/**Left Menu Ends**/

/**Chat Starts**/

/**Left Menu Starts**/
.msg-main {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) 6fr;
}
.msg--menu {
  background: #303338;
  border-right: 1px solid #000;
  height: calc(100vh - 44px);
}
.sm--msg__menu {
  width: 30px;
}
.white--theme .msg--menu {
  background: #eee;
  border-right: 0.3px solid #ccc;
}
.msg--menu__head__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 33px;
  border-bottom: 1px solid #000;
  font-weight: 500;
}
.white--theme .msg--menu__head__content {
  border-bottom: 0.3px solid #ccc;
}
.msg--menu__ctg {
  margin: 10px 0 0 0;
  font-size: 14px;
  color: #9a9a9b;
}
.white--theme .msg--menu__ctg {
  color: #707070;
}
.msg--menu__ctg__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 35px;
}
.msg--menu__ctg__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 23px;
  height: 28px;
  cursor: pointer;
  border-right: none;
  font-size: 12px;
}
.msg--menu__ctg__inner.msg--menu.LeftSelectedClass {
  background: #292b2f !important;
  color: #fff;
}
.white--theme .msg--menu__ctg__inner.msg--menu.LeftSelectedClass {
  background: #ddd !important;
  color: #000;
}
.msg--menu__ctg__top.CategoryLeftSelectedClass {
  background: #292b2f !important;
  color: #fff;
}
.white--theme .msg--menu__ctg__top.CategoryLeftSelectedClass {
  background: #ddd !important;
  color: #000;
}
.msg--menu__ctg__inner .ctg--grey {
  display: flex;
}
.msg--menu__ctg__inner .ctg--white {
  display: none;
}
.msg--menu__ctg__inner:hover {
  background-color: #292b2f !important;
  color: #fff;
}
.white--theme .msg--menu__ctg__inner:hover {
  background-color: #ddd !important;
  color: #000;
}
.msg--menu__ctg__inner:hover .ctg--white {
  display: flex;
}
.msg--menu__ctg__inner:hover .ctg--grey {
  display: none;
}
.msg--menu__ctg__inner.msg--menu__selected .ctg--white {
  display: flex;
}
.msg--menu__ctg__inner.msg--menu__selected .ctg--grey {
  display: none;
}
.ctg--top__lt {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.ctg--top__rt {
  position: relative;
  cursor: pointer;
}
.ctg--top__rt.ctg--top__rt__set {
  display: flex;
  width: 14px;
  height: 14px;
}
.ctg--top__rt img {
  width: 12px;
  height: 12px;
}
.top__lt__fst {
  display: flex;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  margin: 0 0px 0 0;
}
.msg--menu__ctg__inner .top__lt__fst img {
  width: 13px;
  height: 14px;
}
.ctg--top__lt span {
  font-weight: 500;
}
.msg--menu__ctg__inner .ctg--top__lt span {
  font-weight: 400;
}
.msg--menu__ctg__inner .top__lt__fst {
  margin: 0 8px 0 0;
}
.msg--menu__chat {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
}
.msg--menu__chat:hover {
  background-color: #292b2f;
  color: #fff;
}
.white--theme .msg--menu__chat:hover {
  background-color: #ddd;
  color: #000;
}
.msg--menu__selected {
  background-color: #2152a0 !important;
  color: #fff;
}
.msg--menu__chat__user {
  display: flex;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  background: #e50d4b;
  margin: 0 15px 0 0;
}
.msg--menu__chat__user img {
  max-width: 22px;
  max-height: 22px;
  width: auto;
  height: auto;
  border-radius: 100%;
}
.msg--menu__chat__user span {
  font-size: 10px;
  font-weight: 600;
  color: #fff;
}
.white--theme .msg--menu__chat__user span {
  color: #000;
}
/**Left Menu Ends**/

/**Chat body Starts**/
.msg--body {
  display: flex;
}
.msg--body__lt {
  width: 100%;
}
.msg--body__lt.msg--body__lt__sm {
  max-width: calc(100% - 300px);
}
.msg--body__lt.msg--body__rt {
  width: 300px;
  border-left: 1px solid #000;
}
.white--theme .msg--body__lt.msg--body__rt {
  border-left: 1px solid #ccc;
}
.msg--body__lt__head {
  display: flex;
  height: 33px;
  border-bottom: 1px solid #071e33;
  align-items: center;
  padding: 0 15px;
}
.white--theme .msg--body__lt__head {
  border-bottom: 0.3px solid #ccc;
}
.msg--body__messages {
  height: calc(100vh - 97px);
  position: relative;
}
.msg--body__messages__chats {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  max-height: calc(100vh - 100px);
}
.msg--body__messages__chats__scroll {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 120px;
  max-height: calc(100vh - 220px);
  overflow: auto;
}
.msg--body__user {
  display: flex;
  align-items: center;
}
.msg--body__user__wrap {
  display: flex;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background: #e50d4b;
}
.msg--body__user__img {
  max-width: 22px;
  max-height: 22px;
  width: auto;
  height: auto;
  border-radius: 100%;
}
.msg--body__initials {
  font-size: 10px;
  font-weight: 600;
}
.msg--body__initials {
  font-size: 10px;
  font-weight: 600;
}
.msg--body__name {
  font-weight: 600;
  margin: 0 0 0 10px;
}
.msg--body__type {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 15px;
}
.msg--body__type textarea {
  background: #3b3e46;
  border: 1px solid #3b3e46;
  resize: none;
  border-radius: 10px 10px 0 0;
  width: 100%;
  min-height: 70px;
  height: 70px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0;
  font-family: "Poppins", sans-serif;
  padding: 24px 50px 10px 50px;
  overflow: hidden;
}
.white--theme .msg--body__type textarea {
  background: #ddd;
  border: 1px solid #ddd;
  color: #000;
}
.msg--body__attachment {
  position: absolute;
  left: 30px;
  display: flex;
  top: 25px;
  z-index: 100;
}
.msg--body__date_sepr {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9b;
  position: relative;
  padding: 0 15px;
  margin: 20px 0;
}
.white--theme .msg--body__date_sepr {
  color: #707070;
}
.msg--body__date_sepr {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9b;
  position: relative;
  padding: 0 15px;
  margin: 20px 0;
}
.msg--body__date_sepr span {
  position: relative;
  z-index: 100;
  background: #2e3239;
  padding: 0 15px;
}
.white--theme .msg--body__date_sepr span {
  background: #f5f5f5;
}
.msg--body__date_sepr hr {
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
  margin: 0;
  border-width: 0.1px;
  z-index: 99;
}
.msg--body__post__chat {
  padding: 10px 15px;
  margin: 0 0 10px 0;
  position: relative;
}
.msg--body__post__chat:hover {
  background-color: #292b2f;
}
.white--theme .msg--body__post__chat:hover {
  background-color: #eee;
}
.post__chat__name__time {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
}
.post__chat__user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #e50d4b;
}
.post__chat__user img {
  max-width: 30px;
  max-height: 30px;
  width: auto;
  height: auto;
  border-radius: 100%;
}
.post__chat__message {
  margin: 0 0 0 10px;
}
.post__chat__name {
  font-size: 15px;
}
.post__chat__time {
  font-weight: 400;
  color: #9a9a9b;
  margin: 0 0 0 10px;
}
.post__chat__main__message {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
}
.post__chat__message__time {
  display: flex;
  width: 40px;
}
.edited--message {
  width: 12px;
  height: 12px;
  margin: -1px 0 0 10px;
  opacity: 0.5;
}
.post__chat__message__text {
  width: calc(100% - 40px);
  white-space: pre-line;
}
.message-attachment {
  display: flex;
  padding: 10px 0 0 40px;
  gap: 8px;
}
.message-attachment a {
  background: #3b3e46;
  border-radius: 8px;
  height: 230px;
  align-items: center;
  display: flex;
  max-width: 330px;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #4a4a4b;
  padding: 0 8px;
  cursor: zoom-in !important;
  margin: 0 0 8px 0;
  min-width: 120px;
}
.white--theme .message-attachment a {
  background: #eee;
  border: 1px solid #ddd;
  color: #000 !important;
}
.message-attachment a img {
  width: 100%;
  height: auto;
}
.msg--body__actions__link {
  position: absolute;
  background: #222326;
  border: 0.2px solid #ccc;
  z-index: 101;
  left: 30px;
  bottom: calc(100% - 20px);
  padding: 5px 0;
}
.msg--body__actions__link a {
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 0 12px;
  height: 30px;
}
.msg--body__actions__link a:hover {
  background: #292b2f;
}
.msg--body__actions__link a img {
  margin: 0 10px 0 0;
}
.msg--body__uploader {
  position: absolute;
  background: #222326;
  z-index: 102;
  border: 0.2px solid #ccc;
  left: 30px;
  bottom: calc(100% - 0px);
  width: 400px;
}
.msg--body__smiley__anchor {
  position: absolute;
  right: 30px;
  top: 22px;
  transition: all 0.3s ease;
}
.msg--body__smiley__anchor:hover img {
  transform: scale(1.2);
  transition: all 0.3s ease;
  /* transform: rotate(360deg); */
}
.msg--body__picker {
  position: absolute;
  right: 30px;
  bottom: 55px;
  z-index: 100;
}
.msg--body__send {
  display: flex;
  margin: 0 15px;
  justify-content: flex-end;
  background: #292b2f;
  border-radius: 0 0 10px 10px;
  padding: 8px 15px;
}
.white--theme .msg--body__send {
  background: #ccc;
}
.msg--body__send__anchor {
  display: flex;
  transition: all 0.3s ease;
}
.msg--body__send__btnw {
  display: flex;
  transition: all 0.3s ease;
}
.msg--body__send__btnb {
  display: none;
  transition: all 0.3s ease;
}
.msg--body__send__anchor:hover .msg--body__send__btnw {
  display: none;
  transition: all 0.3s ease;
}
.msg--body__send__anchor:hover .msg--body__send__btnb {
  display: flex;
  position: relative;
  transform: scale(1.2);
  transition: all 0.3s ease;
}
.msg--body__picker aside.emoji-picker-react {
  background: #292b2f;
  border: 1px solid #303338;
  box-shadow: none;
}
.white--theme .msg--body__picker aside.emoji-picker-react {
  background: #fff;
  border: 1px solid #ccc;
}
.msg--body__picker .emoji-picker-react input.emoji-search {
  border: 1px solid #3b3e46;
  background: #3b3e46;
  color: #fff;
}
.white--theme .msg--body__picker .emoji-picker-react input.emoji-search {
  border: 1px solid #eee;
  background: #f5f5f5;
  color: #000;
}
.msg--body__picker .emoji-picker-react .emoji-group::before {
  color: #fff;
  background: #292b2f;
}
.white--theme .msg--body__picker .emoji-picker-react .emoji-group::before {
  color: #000;
  background: #fff;
}
.msg--body__picker .emoji-picker-react .emoji-categories button {
  filter: invert(1);
  opacity: 1 !important;
}
.white--theme .msg--body__picker .emoji-picker-react .emoji-categories button {
  filter: invert(0);
}
.msg--body__thread {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.msg--body__thread__icons {
  display: flex;
  align-items: center;
}
.msg--body__thread__icons a {
  display: flex;
  margin: 0 0 0 10px;
}
.msg--body__thread__icons a:first-child {
  margin: 0;
}

/**Thread Starts**/
.post--chat__thread__main {
  font-size: 12px;
  padding: 0 0 0 40px;
  display: inline-block;
  margin: 15px 0 10px 0;
}
.post--chat__thread {
  display: flex;
  background: #3b3e46;
  padding: 8px 15px;
  border-radius: 5px;
  align-items: center;
}
.white--theme .post--chat__thread {
  background: #ddd;
}
.white--theme .post--chat__thread__message {
  color: #000 !important;
}
.post--chat__thread__message {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.post--chat__thread__count {
  display: flex;
  align-items: center;
  margin: 0 0 0 12px;
  color: #007fff;
}
.post--chat__thread__count img {
  width: 4px;
  height: 8px;
  margin: 0 0 0 5px;
}
.post--chat__thread__time {
  font-size: 10px;
  color: #9a9a9b;
  margin: 0 0 0 15px;
}
.white--theme .post--chat__thread__time {
  color: #707070;
}
/**Thread Ends**/

/**Emoji Starts**/
.msg--body__emoji {
  display: none;
  position: absolute;
  right: 15px;
  top: -16px;
  background: #3b3e46;
  border-radius: 5px;
  align-items: center;
  padding: 5px 5px;
}
.white--theme .msg--body__emoji {
  background: #bbb;
}
.msg--body__emoji:hover {
  display: flex;
}
.msg--body__post__chat:hover .msg--body__emoji {
  display: flex;
}
.msg--body__emoji a {
  display: flex;
  margin: 0 4px;
}
.edit--del {
  position: relative;
}
.edit--del--drop {
  position: absolute;
  right: 12px;
  background: #222326;
  border: 0.2px solid #ccc;
  top: 25px;
  width: 150px;
  padding: 10px 0;
}
.white--theme .edit--del--drop {
  background: #bbb;
  border: 0.2px solid #ccc;
}
.edit--del--drop a {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  height: 26px;
  padding: 0 10px;
}
.edit--del--drop a:last-child span {
  color: #e50d4b;
}
.edit--del--drop a:hover {
  background: #292b2f;
}
.white--theme .edit--del--drop a:hover {
  background: #eee;
}
.edit--del--drop img {
  width: 13px;
  height: 13px;
  margin: 0 10px 0 0;
}
.del--confirm {
  display: flex;
  margin: 10px 0 10px 0;
  font-size: 15px;
}
/**Emoji Ends**/

/**Reactions Starts**/
.msg--body__react__tags {
  display: flex;
  padding: 0 0 0 40px;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  margin: 5px 0 0 0;
}
.msg--body__reacts {
  display: flex;
}
.msg--body__reacts a {
  display: flex;
  align-items: center;
  background-color: rgba(0, 127, 255, 0.15);
  border: 1px solid #007fff;
  border-radius: 5px;
  margin: 0 5px 0 0;
  padding: 1px 5px;
}
.msg--body__reacts a img {
  margin: 0 5px 0 0;
}
/**Reactions Ends**/

/**Chat body Ends**/

/**Chat Ends**/

/** Tasks Starts  **/
.sm-check--btn {
  margin: 0 !important;
  padding: 0 0 0 30px !important;
}
.sm-check--btn input {
  width: 22px !important;
  height: 22px !important;
  top: auto !important;
}
.sm-check--btn .check--mark {
  width: 20px;
  height: 20px;
  top: auto !important;
}
.sm-check--btn.check--btn .check--mark::after {
  top: auto !important;
  width: 6px !important;
  height: 10px !important;
}
.sm-check--btn .input--check__text {
  font-size: 14px;
}
.task-board {
  background: #303338;
  width: 300px;
  min-width: 300px;
  border-radius: 2px;
}
.white--theme .task-board {
  background: #eee;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
.task-list {
  max-height: calc(100vh - 150px);
  padding: 10px;
}
.task-head {
  display: flex;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  letter-spacing: 1px;
}
/* .white--theme .task-head {
  background: #fff;
} */
.s-tasks {
  background: #292b2f;
  padding: 20px 10px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer !important;
}
.white--theme .s-tasks {
  background: #fff;
}
.task-detail-fix {
  position: fixed;
  right: 0;
  top: 44px;
  background: #000;
  width: 400px;
  z-index: 100;
  height: calc(100vh - 44px);
}
.white--theme .task-detail-fix {
  background: #e3e3e3;
}
.expand-view {
  width: calc(100% - 60px);
}

.task-chat-sm .msg--body__messages {
  max-height: calc(100vh - 400px);
}
.task-chat-sm .msg--body__messages__chats {
  max-height: calc(100vh - 403px);
}
.task-chat-sm .msg--body__messages__chats__scroll {
  max-height: calc(100vh - 523px);
}
.nev-nncs {
  height: calc(100vh - 238px);
  overflow: auto;
}
.nev-nncs2 {
  height: calc(100vh - 198px);
  overflow: auto;
}
.nev-ycs {
  height: calc(100vh - 770px);
  overflow: auto;
}
.task-chat-sm {
  /* border-top: 1px solid #4c4c4c; */
}
.m-accord {
  background: #3b3e46;
  padding: 5px 10px;
  margin: 10px 0 0 0;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
}
.btn-internal {
  font-size: 12px;
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0;
  color: #e50d4b !important;
  text-decoration: underline !important;
  margin: 0 15px 0px 0;
}
.white--theme .m-accord {
  background: #ccc;
  color: #000 !important;
}
.asgn-task {
  font-size: 11px;
  color: #8c8c8c;
  font-style: italic;
  margin: 5px 0 0 0;
}
.event-title {
  font-size: 20px;
  margin: 0;
}
.toggle-btns {
  border-radius: 0px;
  font-size: 12px;
}
.toggle-btns a {
  padding: 0 6px;
  min-width: 120px;
  height: 32px;
  color: #fff !important;
  text-transform: uppercase;
}
.white--theme .toggle-btns a {
  color: #000 !important;
}
.toggle-btns a:last-child {
  border: none;
}
.toggle-btns a.selected {
  color: #fff !important;
  background: #007fff;
}
.white--theme .toggle-btns a.selected {
  background: #007fff;
}
.task-name {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 18px;
}
.task-descp {
  font-size: 12px;
  color: #9a9a9b;
}
.sm-greyfont {
  font-size: 11px;
  color: #9a9a9b;
}
.timer-ss {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin: 5px 0 0 0;
}
/* .white--theme .timer-ss {
  background: #fff;
} */
.play-pause-btns {
  background: #303338;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  border-radius: 100%;
}
/* .white--theme .play-pause-btns {
  background: #fff;
} */
.play-pause-btns img {
  width: 13px;
  height: 13px;
}
.btn-play {
  background: #207f20;
}
.btn-pause {
  background: #f8b516;
}
.btn-stop {
  background: #ff0000;
}
.run-timer {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
}
.white--theme .run-timer {
  background: #fff;
}
.time-total {
  font-size: 14px;
  margin: 5px 0 0 0;
  font-weight: 400;
  gap: 10px;
}
.total-lg-font {
  font-size: 16px;
  font-weight: 500;
}
.p-lr10 {
  padding: 0 10px !important;
}
/** Tasks Ends  **/

/*Theme Btn Starts*/
.btn--theme {
  position: fixed;
  top: 10px;
  right: 110px;
  z-index: 1000;
}
/*Theme Btn Ends*/

/**App Dropdowns Starts**/
.app--drops {
  position: absolute;
  left: 0;
  background: #222326;
  border: 0.2px solid #ccc;
  width: 190px;
  z-index: 100;
  padding: 10px 0;
}
.white--theme .app--drops {
  background: #bbb;
}
.app--drops__item {
  display: flex;
  font-size: 12px;
  align-items: center;
  height: 30px;
  padding: 0 12px;
}
.app--drops__item img {
  margin: 0 10px 0 0;
}
.app--drops__item:hover {
  background-color: #292b2f;
}
.white--theme .app--drops__item:hover {
  background-color: #eee;
}
/**App Dropdowns Ends**/

/**Settings Starts**/
.settings--main {
  color: #9a9a9b;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}
.settings--tab.nav.nav-tabs {
  border-bottom: 1px solid rgba(154, 154, 155, 0.2) !important;
}
.settings--main .settings--tab {
  padding: 20px 50px 0px 50px;
}
.settings--main .tab-pane {
  padding: 0 0 0 50px;
  max-width: 600px;
}
.login--strong__passcode .p-error {
  color: #bd362f;
  background-image: url(https://travelapp.manyge.com//images/icon-cross.svg);
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 12px;
  padding: 0 0 0 20px;
}
.login--strong__passcode .p-ok {
  color: #51a351;
  background-image: url(https://travelapp.manyge.com/images/Icon-check.svg);
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 12px;
  padding: 0 0 0 20px;
}
/**Settings Ends**/

/**Create modal Starts**/
.modal--create {
  color: #9a9a9b;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  min-height: 550px;
}
.modal--create__about {
  font-weight: 400;
  display: inline-block;
  width: 100%;
  margin: 15px 0 0 0;
}
.modal--create__inputs {
  display: inline-block;
  width: 100%;
}
option {
  background: #292b2f !important;
}
.white--theme option {
  background: none !important;
}
.input--text {
  display: flex;
  margin: 0 0 2px 0;
}
.input--type__text {
  /* display: flex; */
  width: 100%;
  height: 44px;
  background: no-repeat;
  border-radius: 5px;
  border: 1px solid #9a9a9b;
  color: #f5f5ff;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
}

.input--type__text_no_border {
  /* display: flex; */
  width: 100%;
  background: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input--type__textsm {
  height: 32px;
  font-size: 14px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background-image: url(/public/images/calendar.svg);
  cursor: pointer;
  background-size: 18px;
}
.white--theme .input--type__text,
.white--theme .input--type__text_no_border {
  color: #000;
}
.modal--create__inputs textarea {
  /* resize: none; */
  height: 70px;
  padding: 15px;
}
.input--type__textarea {
  resize: vertical;
  height: 100px;
  font-size: 14px;
}
.modal--create__inputs__radio {
  display: flex;
  align-items: center;
}
.modal--create__inputs__radio {
  display: flex;
  align-items: center;
}
.input--radio {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 0 0 48px;
  margin: 0 50px 15px 0;
}
.input--radio__text,
.input--check__text {
  font-size: 18px;
  font-weight: 400;
}
.create--hr {
  margin: 20px -25px 0 -25px;
  opacity: 1;
  border-top: 0.2px solid #9a9a9b;
}
.modal--create__add {
  float: left;
  width: 100%;
  border: 1px solid #9a9a9b;
  border-radius: 5px;
  padding: 6px 10px;
  margin: 0 0 -5px 0;
}
.modal--create__add__member {
  float: left;
  margin: 0 5px;
}
.modal--create__add__member__flex {
  display: flex;
  align-items: center;
  background: #303338;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  font-size: 10px;
  color: #f5f5ff;
  font-weight: 600;
}
.modal--create__add__member__image {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
  background: #e50d4b;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.modal--create__add__member__image img {
  width: auto;
  height: auto;
  max-width: 20px;
  max-height: 20px;
}
.modal--create__add__member__name {
  margin: 0 8px;
  font-size: 11px;
}
.remove--user {
  width: 10px;
  height: 10px;
}
.modal--button {
  display: flex;
  margin: 10px 0 0px 0;
  justify-content: flex-end;
}
.del--modal__btn {
  gap: 15px;
  margin: 25px 0 0 0 !important;
}
.modal--button a {
  display: flex;
  background: #007fff;
  height: 38px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px;
}
.white--theme .modal--button a {
  color: #9a9a9b !important;
  background: #fff !important;
  border: 2px solid #9a9a9b !important;
}
.white--theme .modal--button a.cancel {
  color: #fff !important;
  background: #e50d4b !important;
  border: 2px solid #e50d4b !important;
}
.del--modal__btn a:first-child {
  background: none;
  border: 2px solid #9a9a9b;
  min-width: 102px;
}
.del--modal__btn a:last-child {
  background: #e50d4b;
  min-width: 102px;
}
.edit--input {
  position: relative;
}
.edit--input a {
  position: absolute;
  right: 15px;
  top: calc(50% - 7.5px);
  display: none;
}
.edit--input:hover a {
  display: flex;
}
.edit--input input,
.edit--input textarea {
  border: none;
  padding-left: 0;
}
.edit--input.edit--input--start input,
.edit--input.edit--input--start textarea {
  border: 1px solid #9a9a9b;
  padding-left: 15px;
}
/* .edit--input:hover input,
.edit--input:hover textarea {
  border: 1px solid #9a9a9b;
  padding-left: 15px;
} */
.channel--archive {
  display: flex;
  margin: 10px 0 20px 0;
}
.channel--archive a {
  display: flex;
  color: #e50d4b !important;
  font-size: 18px;
  align-items: center;
  font-weight: 400;
}
.channel--archive a img {
  margin: 0 20px 0 0;
}
.modal--create__inputs.search--members input {
  background-image: url(/public/images/search.png);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 15px center;
  padding: 0 0 0 45px;
}
.add-remaining {
  display: flex;
  align-items: center;
  margin: 20px 0 -10px 0;
}
.add-remaining span {
  width: auto;
  margin: 0;
}
.add-remaining a {
  font-size: 12px;
  line-height: 12px;
  padding: 10px 12px;
  background: #007fff;
  border-radius: 5px;
  margin: 0 0 0 20px;
  font-weight: 400;
}
.online--users {
  display: inline-block;
  width: 100%;
  margin: 10px 0 0 0;
}
.online--users__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 0 0;
}
.online--users__data {
  display: flex;
  align-items: center;
}
.online--users__images {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #e50d4b;
  border-radius: 100%;
  justify-content: center;
  position: relative;
}
.online--users__images img {
  max-width: 30px;
  max-height: 30px;
  width: auto;
  height: auto;
  border-radius: 100%;
}
.online--users__status {
  position: absolute;
  right: 1px;
  bottom: 0px;
  width: 7px !important;
  height: 7px !important;
}
.online--users__status {
  position: absolute;
  right: 1px;
  bottom: 0px;
}
.online--users__name {
  margin: 0 0 0 15px;
}
.online--users__remove {
  display: none;
}
.online--users__details:hover .online--users__remove {
  display: flex;
}
/**Create modal Ends**/

/**App Modals Starts**/
.modal-dialog {
  min-width: 600px;
  margin-top: 100px !important;
}
.modal-content {
  background: #292b2f !important;
  border-radius: 10px !important;
  color: #f5f5ff !important;
  font-family: "Poppins", sans-serif !important;
}
.white--theme .modal-content {
  background: #fff !important;
}
.modal-header {
  border: none !important;
  height: 60px !important;
  padding: 0 25px !important;
  justify-content: space-between;
  align-items: center !important;
  display: flex !important;
}
.modal-header img {
  margin: -5px 10px 0 0;
}
.modal-title.h4 {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.white--theme .modal-title.h4 {
  color: #000;
}
.modal-header .btn-close {
  padding: 0 !important;
  font-size: 20px !important;
  margin: 0 !important;
  color: #f5f5ff !important;
  filter: invert(1) !important;
}
.white--theme .modal-header .btn-close {
  filter: invert(0) !important;
}
.modal-body {
  padding: 0 25px 25px 25px !important;
}
/**App Modals Ends**/

/**Radio Starts**/
/* The radio--btn */
.radio--btn {
  position: relative;
}

/* Hide the browser's default radio button */
.radio--btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  left: 0;
  top: 0;
  z-index: 100;
}

/* Create a custom radio button */
.radio--mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: none;
  border-radius: 50%;
  border: 2px solid #9a9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* On mouse-over, add a grey background color */
.radio--btn:hover input ~ .radio--mark {
  background-color: none;
}

/* When the radio button is checked, add a blue background */
.radio--btn input:checked ~ .radio--mark {
  background-color: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio--mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio--btn input:checked ~ .radio--mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio--btn .radio--mark:after {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #9a9a9b;
}
/**Radio Ends**/

/**Checkbox Starts**/
.check--btn {
  position: relative;
}

/* Hide the browser's default radio button */
.check--btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  left: 0;
  top: 0;
  z-index: 100;
}

/* Create a custom radio button */
.check--mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: none;
  border-radius: 5px;
  border: 2px solid #9a9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* On mouse-over, add a grey background color */
.check--btn:hover input ~ .check--mark {
  background-color: none;
}

/* When the radio button is checked, add a blue background */
.check--btn input:checked ~ .check--mark {
  background-color: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check--mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.check--btn input:checked ~ .check--mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.check--btn .check--mark:after {
  width: 8px;
  height: 14px;
  border: solid #9a9a9b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 4px;
}
/**Checkbox Ends**/

/**Scroll Starts**/
.custom--scroll {
  scrollbar-color: #292b2f Transparent;
  scrollbar-width: thin;
  overflow: auto;
}
.custom--scroll::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
  border-radius: 0;
}
.custom--scroll::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}
.custom--scroll::-webkit-scrollbar-thumb {
  background: #292b2f;
  border-radius: 0;
}
.custom--scroll::-webkit-scrollbar-thumb:hover {
  background: #292b2f;
}
/*Light Scroll*/
.white--theme .custom--scroll {
  scrollbar-color: #ddd Transparent;
  scrollbar-width: thin;
}
.white--theme .custom--scroll::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 0;
}
.white--theme .custom--scroll::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}
/**Scroll Ends**/

/**Tabs Starts**/
.nav-link {
  background: none !important;
  border: none !important;
  color: #9a9a9b !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  border-bottom: 3px solid transparent !important;
  padding: 8px 31px !important;
}
.nav-link.active {
  color: #fff !important;
  border-bottom: 3px solid #007fff !important;
}
.nav-link.active:hover {
  color: #fff !important;
  border-bottom: 3px solid #007fff !important;
}
.nav-link:hover {
  border: none !important;
  color: #fff !important;
  border-bottom: 3px solid transparent !important;
}
.modal--create__about.nav.nav-tabs {
  border-bottom: 1px solid rgba(154, 154, 155, 0.2) !important;
}
.modal--create .modal--create__about.nav.nav-tabs {
  margin: 15px 0 0 -25px;
  width: calc(100% + 50px);
  padding: 0 25px;
}
/**Tabs Ends**/
/**Loader Starts**/
.loader-overlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
}
/**Loader Ends**/
/**Uploader Starts**/
.msg--body__dragdrop {
  padding: 0 15px;
  margin: 15px 0;
}
.dropArea {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
}
.dropAreatext {
  border: 2px dashed #ccc;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  color: #ccc;
  position: relative;
  cursor: pointer;
}
.dropAreatext:hover {
  background: #f5f5f5;
}
.remove--image {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
  margin: 20px 0 5px 0;
}
.remove--image img {
  width: auto;
  height: auto;
  max-height: 100px;
  max-width: 100px;
}
.remove--image a {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
}
/**Uploader Ends**/
.min-h-100 {
  min-height: 100px !important;
}

/**Responsive Starts**/
@media (max-width: 980px) {
  .msg--menu__ctg__inner .ctg--white {
    display: flex !important;
  }
}
/**Responsive Ends**/

/*table Starts*/
.overflow-auto {
  overflow: auto;
  max-height: calc(100vh - 80px);
  padding-bottom: 12px;
}
.reuse-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #444;
  min-width: 100%;
}
.white--theme .reuse-table {
  border: 1px solid #ddd;
}
.reuse-table thead tr th {
  background: #101010;
  color: #fff !important;
  font-weight: bold;
  height: 32px;
  padding: 0 5px;
  border-right: 1px solid #444;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-size: 10px;
  text-align: center;
  border-bottom: 1px solid #444;
  line-height: 12px;
}
.white--theme .reuse-table thead tr th {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: #ccc;
}
.reuse-table tbody tr td {
  font-weight: normal;
  height: 32px;
  padding: 0 5px;
  border-right: 1px solid #444;
  font-size: 11px;
  border-bottom: 1px solid #444;
}
.white--theme .reuse-table tbody tr td {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #000 !important;
}
.reuse-table tbody tr:nth-child(2n + 1),
.reuse-table tbody tr:nth-child(2n + 1) td {
  background: #272b32;
}
.white--theme .reuse-table tbody tr:nth-child(2n + 1),
.white--theme .reuse-table tbody tr:nth-child(2n + 1) td {
  background: #fafafa;
}
tr:first-child .sticky-header {
  position: sticky;
  top: -1px;
  z-index: 100;
}
tr:nth-child(2) .sticky-header {
  position: sticky;
  top: 30px;
  z-index: 100;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.input-check {
  width: auto !important;
  height: auto !important;
  accent-color: #2152a0 !important;
}
.reuse-table tbody tr td.col-xxs,
.reuse-table thead tr th.col-xxs {
  width: 100px !important;
}
.reuse-table td input {
  width: 100%;
  background: transparent;
  border: 2px solid transparent;
  height: 32px;
  outline: none;
  color: #fff;
  padding: 0 5px;
}
.white--theme .reuse-table td input {
  color: #000;
}
.reuse-table td input:hover {
  border: 2px solid #9a9a9b;
}
.reuse-table td.p-all0 {
  padding: 0 !important;
}
.reuse-min-wid th,
.reuse-min-wid td {
  min-width: 200px !important;
}
.p-unset {
  position: unset !important;
}
/*table Ends*/

/*Reporting Starts*/
.report-accord {
  margin: 20px 0 0 0;
}
.acco-head {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #9a9a9b;
  padding: 0 0 2px 0;
  margin: 0 0 10px 0;
  letter-spacing: 1px;
  color: #9a9a9b;
}
.btn-info-rep a {
  border: 2px solid #9a9a9b;
  border-radius: 5px;
  color: #9a9a9b !important;
  font-size: 12px;
  padding: 0 10px;
  height: 36px;
}
.c-line {
  display: flex;
  width: 1px;
  height: 15px;
  background: #9a9a9b;
}
.txt-label-sm {
  color: #9a9a9b;
  font-size: 12px;
  text-transform: uppercase;
}
.grid-w {
  width: 100%;
  max-width: 10%;
}
/*Reporting Ends*/

.resizer {
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  z-index: 1;
}

.isResizing {
  background: rgba(1, 0, 0, 2);
}

/* table {
  table-layout: fixed;
} */

/*Filter Drop Starts*/
.table-filter-drop {
  background: #292b2f;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0px rgb(0, 0, 0);
  min-width: 200px;
}
.filter-scroll .no-wrap {
  font-size: 12px;
}
.white--theme .table-filter-drop {
  background: #fff;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
}
/*Support Ticket Starts*/
.status-box {
  height: 26px;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #000 !important;
}
.cursor-p {
  cursor: pointer;
}
.priority-box {
  width: 8px;
  height: 8px;
  border-radius: 30px;
}
tr.cursor-p:hover td {
  background: #1a1a1a !important;
}
.white--theme tr.cursor-p:hover td {
  background: #eee !important;
}
.reuse-border {
  border-right: 1px solid #9a9a9b;
  min-height: 170px;
}
.p-box-change {
  width: 100%;
  max-width: 250px;
  background: #212529;
  height: 32px;
  padding: 0 8px;
  border-radius: 3px;
  font-size: 12px;
  color: #9a9a9b;
  font-weight: 600;
  cursor: pointer;
}
.white--theme .p-box-change {
  background: #ddd;
}
.priority-drop {
  position: absolute;
  background: #212529;
  width: 100%;
  max-width: 250px;
  top: 34px;
  left: 0;
  border-radius: 3px;
}
.white--theme .priority-drop {
  background: #ddd;
}
.priority-drop a {
  padding: 0 8px !important;
  color: #9a9a9b !important;
  text-transform: uppercase;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
}
.grey-box-support {
  background: #212529;
  padding: 0px 8px;
  height: 52px;
  border-radius: 3px;
  cursor: pointer;
}
.white--theme .grey-box-support {
  background: #f5f5f5;
}
.grey-box-support img {
  width: 20px;
  height: auto;
  max-width: 20px;
  max-height: 20px;
}
.grey-box-support img.sup-chev {
  width: 14px;
  height: 6px;
}
.grey-box-drop {
  top: 75px;
  left: 0;
  width: 100%;
  height: auto;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.4);
  padding: 10px 8px;
  position: absolute;
  z-index: 1;
}
.support-drop {
  border: 1px solid #212529;
  background: #212529;
  color: #9a9a9b !important;
  font-size: 14px;
}
.white--theme .support-drop {
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  font-size: 14px;
}
.support-area textarea {
  min-height: 170px;
}
.suport-attach .drad-drop {
  width: 100%;
}
.suport-attach .uploaded-list {
  display: flex;
}
.p-rt {
  right: 30px;
  top: 40px;
  position: absolute;
}
.ticket-imgs img {
  height: 100px;
  width: auto;
}
.bold-font {
  font-weight: 600;
}
.reuse-back-l-g {
  background: #292b2f;
  border-radius: 3px;
  padding: 10px;
}
.white--theme .reuse-back-l-g {
  background: #ececec;
}
.fr-41 {
  grid-template-columns: 4fr 1fr;
}
.flex-as {
  align-items: flex-start;
}
.txt-red {
  color: #ff0000 !important;
}
.wrap-flex {
  flex-wrap: wrap;
}
.txt-grey {
  color: #9a9a9b;
  font-size: 12px;
}
.txt-blue {
  font-size: 14px;
  color: #9a9a9b;
}
.wrap-flex.no-display {
  display: none !important;
}
.sup-input {
  font-size: 14px;
  color: #9a9a9b !important;
}
.remove--image img.upld-img {
  width: auto !important;
  height: 100px !important;
  max-width: unset !important;
  max-height: unset !important;
}
.supp-r-img .remove--image {
  margin: 0 !important;
}
.reuse-btn {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.reuse-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-size: 12px;
  padding: 0 10px;
  background: #007fff;
  margin: 10px 0 0 0;
  border-radius: 3px;
}
/*Support Ticket Ends*/
/*Filter Drop Ends*/

/* Container for the text area */
.dashboard {
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.textarea {
  background-color: white;
  border-radius: 20px;
  width: 30%;
}

.sendbtn {
  position: fixed;
  margin-left: 360px;
  background-color: #2e3239;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 16px;
  cursor: pointer;
}

.sendbtn:hover {
  color: #e50d4b !important;
}

.custom-textarea {
  width: 100%;
  height: 50px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
  margin-bottom: 20px;
}

.custom-textarea:focus {
  border-color: #007bff;
}

.upload-button {
  color: white !important;
  background-color: #2e3239;
  border-radius: 30px;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 18px;
}

.upload-button:hover {
  color: red !important;
}

.uploaded-files-preview {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.uploaded-file-item {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-preview-wrapper {
  display: inline-block;
  position: relative;
  margin: 5px;
  overflow: hidden;
  max-width: 150px;
  max-height: 150px;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.file-preview {
  width: 50px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* .file-preview-logo{
  width: 50px;
  height: 100px;
  border-radius: 30px;
} */

.image-preview .file-preview {
  height: 150px;
}
.file-preview-name {
  font-size: 8px;
  color: black;
}

.other-preview .file-preview-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.remove-file-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
}

.remove-file-button:hover {
  background: #e74c3c;
}

.remove-file-button:focus {
  outline: none;
}

.file-attachment-container {
  display: flex;
  justify-content: flex-start;
}

.file-attachment-container input[type="file"] {
  display: none;
}

.file-attachment-container label:hover {
  background-color: gray;
  color: white !important;
}

.uploaded-file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 220px;
  margin-bottom: 20px;
}

.uploaded-file-item img,
.uploaded-file-item .file-preview-icon {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
}
.empty-div {
  display: none !important;
}
/*****Card Starts****/
.card-i {
  background-color: #303338;
  border-radius: 3px;
  padding: 10px 10px 10px 20px;
  position: relative;
}
.white--theme .card-i {
  background-color: #fff;
  color: #000;
}

.card-status {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 100%;
  clip-path: polygon(0 0, 0% 0, 100% 10px, 100% 100%, 0% 100%);
}
.p-pic {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid #9a9a9b;
}
.p-pic img {
  display: flex;
  width: auto;
  height: auto;
  max-width: 62px;
  max-height: 62px;
  border-radius: 100%;
}
.time-rec {
  background: #2152a0;
  padding: 3px 6px;
  border-radius: 7px;
  color: #fff;
}
.task-c-title {
  background: #e50d4b;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  min-width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  height: 25px;
  border-radius: 20px;
}
.rec-lbl {
  font-size: 11px;
  text-transform: uppercase;
}
.rec-val {
  font-size: 14px;
  font-weight: 700;
}
.pp-wid {
  width: calc(100% - 70px);
}
.task-title {
  font-size: 12px;
  line-height: 12px;
}
.card-i:first-child .card-status {
  background: #f42336;
}
.strip-g {
  background: #2ee2a1;
}
.c-green {
  background: #98cb4a;
}
.br-green {
  border: 2px solid #98cb4a;
}
.strip-y {
  background: #f7d842;
}
.c-yellow {
  background: #f7d842;
}
.br-yellow {
  border: 2px solid #f7d842;
}
.strip-r {
  background: #f42336;
}
.c-red {
  background: #f42336;
}
.br-red {
  border: 2px solid #f42336;
}
.user-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.user-desg {
  font-size: 12px;
  color: #9a9a9b;
}
/*****Card Ends****/
/* Responsive Styling */
@media (max-width: 768px) {
  .uploaded-files-preview {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .uploaded-file-item {
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .uploaded-file-item {
    max-width: 100px;
  }

  .file-preview {
    height: 100px;
  }
}
